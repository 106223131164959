.selected-item {
  font-size: 16px;
  color: #005cbc;
  font-weight: 700;
}

.unselected-item {
  font-size: 16px;
  color: #616161;
  font-weight: 400;
}

.close-icon-text {
  font-size: 16px;
  font-weight: 700;
}
