:root {
  --blue-1: #0348dc;
  --blue-2: #0361eb;
  --blue-3: #11398f;
  --blue-4: #002c5f;
  --blue-5: #558bff;
  --blue-6: #071c66;
  --blue-7: #21a6ed;
  --blue-8: #0c7af6;
  --blue-9: #266bff;
  --blue-10: #1976d2;
  --blue-11: #0000ff;
  --blue-12: #0c2b7e;
  --blue-13: #1353de;
  --blue-14: #2171cf;
  --blue-15: #a1b3c7;
  --blue-16: #4af1e2;
  --blue-17: #0348dc33;
  --blue-18: #61dafb;
  --blue-19: #28458e;
  --blue-20: #0096d6;
  --blue-21: #005cbc;
  --blue-22: #eff5fc;
  --blue-23: #1d4659;
  --blue-24: #003771;
  --blue-25: #19363e;
  --blue-26: #e7effb;
  --blue-27: #cce4ee;
  --blue-28: #aad2e2;
  --blue-29: #dfeaf9;
  --blue-30: #0096d1;
  --blue-31: #117d9f;
  --blue-32: #f3f7fd;
  --blue-33: #33abda;
  --blue-34: #33abda;
  --blue-35: #8458cc;
  --blue-36: #4f357a;
  --blue-37: #eef6f9;
  --blue-38: #d7e5f8;
  --blue-39: #669dd7;
  --blue-40: #bbdbe8;
  --blue-41: #0348dc0f;
  --green-1: #008553;
  --green-2: #2b6206;
  --green-3: #59af09;
  --green-4: #ccece1;
  --green-5: #00984a;
  --green-6: #cce7dd;
  --green-7: #339d75;
  --green-8: #006a42;
  --red-1: #8c0733;
  --red-2: #5c020d;
  --red-3: #ee337a;
  --red-4: #a9164e;
  --red-5: #ff0000;
  --red-6: #eed3dc;
  --red-7: #f03a3a;
  --red-8: #b90404;
  --red-9: #df002c;
  --red-10: #d40020;
  --red-11: #aa001a;
  --yellow-1: #ffd809;
  --yellow-2: #b97800;
  --yellow-3: #e6e7bd;
  --yellow-4: #eef116;
  --yellow-5: #eb7b30;
  --yellow-6: #fff2d2;
  --yellow-7: #ffbf1c;
  --yellow-8: #fff6e6;
  --yellow-9: #ffcc49;
  --yellow-10: #ffa149;
  --white-1: #ffffff;
  --white-2: #e6e8ed;
  --white-3: #f4f5f7;
  --white-4: #eeeeee;
  --white-5: #f5f5f5;
  --white-6: #f0f0f0;
  --white-7: #fbfcfe;
  --gray-1: #fafafa;
  --gray-2: #e5e5e5;
  --gray-3: #ecf7fd;
  --gray-4: #fffdeb;
  --gray-5: #ffebeb;
  --gray-6: #f8f9fc;
  --gray-7: #c4c4c4;
  --gray-8: #9d9d9d;
  --gray-9: #808080;
  --gray-10: #ccd6e2;
  --gray-11: #d6e3f3;
  --gray-12: #e3e3e3;
  --gray-13: #535353;
  --gray-14: #e2e0dc;
  --gray-15: #dddddd;
  --gray-16: #f2f2f2;
  --gray-17: #c9c6c6;
  --gray-18: #0000001a;
  --gray-19: #0000003d;
  --gray-20: #64646f33;
  --gray-21: #00000040;
  --gray-22: #525252;
  --gray-23: #dadada;
  --gray-24: #d9d9d9;
  --gray-25: #efefef;
  --gray-26: #646464;
  --gray-27: #616161;
  --gray-28: #3a404d;
  --gray-29: #e8e8e8;
  --gray-30: #979797;
  --gray-31: #a0a0a0;
  --gray-32: #c8c8c8;
  --gray-33: #d0d0d0;
  --gray-34: #c0c0c0;
  --gray-35: #818181;
  --gray-36: #dfdfdf;
  --black-1: #000000;
  --black-2: #1d1d1d;
  --black-3: #2a2a2a;
  --black-4: #6b6b6b;
  --black-5: #3a3a3a;
  --black-6: #4a4a4a;
  --black-7: #1312122e;
  --black-8: #333333;
  --black-9: #282c34;
}
