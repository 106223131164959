:root {
  --blue-1: #005cbc;
  --blue-2: #eff5fc;
  --blue-3: #d7e5f8;
  --blue-4: #f3f7fd;
  --white-1: #ffffff;
  --white-2: #fbfcfe;
  --gray-1: #ecf7fd;
  --gray-2: #616161;
  --gray-3: #c0c0c0;
  --gray-4: #3a3a3a;
  --orange-1: #ffa149;
  --red-1: #d40020;
}
