@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

$font-stack: "Inter", sans-serif;

html {
  font-size: 62.5%;
  height: 100%;
  width: 100%;
}
body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.75rem;
  letter-spacing: 0px;
  text-align: left;
  height: 100%;
  width: 100%;
  color: "#000";
  background: #f3f7fd;
}
#root {
  padding-top: 60px;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace, sans-serif;
}
* {
  outline: none;
}
a {
  color: #1d4659;
  text-decoration: none;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
