:root {
  /*
  |--------------------------------------------------------------------------
  | Pricing color codes 
  |--------------------------------------------------------------------------
  |
  | Here is where you can register the color codes for pricing module.
  | These color codes will be available across the application.
  |
  */
  --pricing-gray-1: #d0d0d0;
  --pricing-gray-2: #c0c0c0;
  --pricing-gray-3: #a0a0a0;
  --pricing-gray-4: #616161;
  --pricing-gray-5: #3a3a3a;
  --pricing-gray-6: #8f9092;
  --pricing-gray-7: #d1d1d1;
  --pricing-gray-8: #dddbda;
  --pricing-gray-9: #d9d9d9;
  --pricing-gray-10: #c4c4c4;
  --pricing-blue-1: #005cbc;
  --pricing-blue-2: #d7e5f8;
  --pricing-blue-3: #0348dc;
  --pricing-blue-4: #aad2e2;
  --pricing-blue-5: #119e9f;
  --pricing-blue-6: #009de2;
  --pricing-blue-7: #dfeaf9;
  --pricing-blue-8: #0a4b5f;
  --pricing-blue-9: #1d79e7;
  --pricing-blue-10: #005a7d;
  --pricing-blue-11: #117d9f;
  --pricing-red-1: #d40020;
  --pricing-pink-1: #b05788;
  --pricing-pink-2: #6a3452;
  --pricing-green-1: #008553;
  --pricing-green-2: #30e181;
  --pricing-green-3: #53c5c5;
  --pricing-green-4: #009495;
  --pricing-white-1: #fbfcfe;
  --pricing-white-2: #f3f7fd;
  --pricing-white-3: #eff5fc;
  --pricing-white-4: #ffffff;
  --pricing-white-5: #efefef;
  --pricing-white-6: #e5e5e5;
  --pricing-white-7: #f5f5f5;
  --pricing-black-1: #000000;
}
